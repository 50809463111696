.select2-dropdown {
 background-color: white;

 border: 1px solid #aaa;
 border-radius: 4px;

 box-sizing: border-box;

 display: block;

 position: absolute;
 left: -100000px;

 width: 100%;

 z-index: 1051;
}

.select2-results {
 display: block;
}

.select2-results__options {
 list-style: none;
 margin: 0;
 padding: 0;
}

.select2-results__option {
 padding: 6px;

 user-select: none;
 -webkit-user-select: none;
}

.select2-results__option--selectable {
 cursor: pointer;
}

.select2-container--open .select2-dropdown {
 left: 0;
}

.select2-container--open .select2-dropdown--above {
 border-bottom: none;
 border-bottom-left-radius: 0;
 border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
 border-top: none;
 border-top-left-radius: 0;
 border-top-right-radius: 0;
}

.select2-search--dropdown {
 display: block;
 padding: 4px;

 .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;

  &::-webkit-search-cancel-button {
   -webkit-appearance: none;
  }
 }

 &.select2-search--hide {
  display: none;
 }
}