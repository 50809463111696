.select2-container {
 box-sizing: border-box;
 width: 100%!important;
 display: inline-block;
 margin: 0;
 position: relative;
 vertical-align: middle;

 @import "single";
 @import "multiple";
}

@import "dropdown";

.select2-close-mask {
 border: 0;
 margin: 0;
 padding: 0;
 display: block;
 position: fixed;
 left: 0;
 top: 0;
 min-height: 100%;
 min-width: 100%;
 height: auto;
 width: auto;
 opacity: 0;
 z-index: 99;

 // styles required for IE to work

 background-color: #fff;
 filter: alpha(opacity=0);
}

.select2-hidden-accessible {
 border: 0 !important;
 clip: rect(0 0 0 0) !important;
 -webkit-clip-path: inset(50%) !important;
 clip-path: inset(50%) !important;
 height: 1px !important;
 overflow: hidden !important;
 padding: 0 !important;
 position: absolute !important;
 width: 1px !important;
 white-space: nowrap !important;
}

@import "theme/default/layout";
@import "theme/classic/layout";